import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-page" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "login-normal" }
const _hoisted_4 = { class: "inner login-container" }
const _hoisted_5 = { class: "tagline" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "login-body" }
const _hoisted_8 = { class: "forgot-password-text" }
const _hoisted_9 = { class: "strike" }
const _hoisted_10 = { class: "or-text" }
const _hoisted_11 = { class: "inner login-container regis" }
const _hoisted_12 = { class: "regis-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_ValidationPasswordField = _resolveComponent("ValidationPasswordField")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_XidButton = _resolveComponent("XidButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("login.loginTagline")), 1),
          _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.$t("login.loginTitle")), 1),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_ValidationTextField, {
              class: "login-input-field",
              placeholder: _ctx.$t('login.loginEmail'),
              "max-length": 320,
              message: {
                error_required: _ctx.$t('errors.required'),
                error_format_email: _ctx.$t('errors.formatEmail'),
              },
              name: "email",
              rules: "required|email"
            }, null, 8, ["placeholder", "message"]),
            _createVNode(_component_ValidationPasswordField, {
              class: "login-input-field mg",
              placeholder: _ctx.$t('login.loginPassword'),
              name: "password",
              rules: "password|required",
              onKeypress: _withKeys(_ctx.submit, ["enter"])
            }, null, 8, ["placeholder", "onKeypress"]),
            _createVNode(_component_FlatButton, {
              class: "login-btn",
              text: _ctx.$t('login.loginBtn'),
              onOnClick: _ctx.submit,
              onKeypress: _withKeys(_ctx.submit, ["enter"])
            }, null, 8, ["text", "onOnClick", "onKeypress"]),
            _createElementVNode("p", _hoisted_8, [
              _createTextVNode(_toDisplayString(_ctx.$t("login.loginForgotPassword")) + " ", 1),
              _createVNode(_component_router_link, {
                to: { name: 'ForgotPassword' },
                class: "link",
                tag: "button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("login.thisText")), 1)
                ]),
                _: 1
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("common.dot")), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("login.loginOr")), 1)
            ]),
            _createVNode(_component_XidButton, {
              class: "login-xid-btn",
              text: _ctx.$t('login.loginXidBtn'),
              onOnClick: _ctx.xidLogin
            }, null, 8, ["text", "onOnClick"])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("p", _hoisted_12, [
            _createTextVNode(_toDisplayString(_ctx.$t("login.loginRegisText")) + " ", 1),
            _createVNode(_component_router_link, {
              to: { name: 'SignUp' },
              class: "link",
              tag: "button"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("login.thisText")), 1)
              ]),
              _: 1
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("common.dot")), 1)
          ])
        ])
      ])
    ])
  ]))
}