
// @ is an alias to /src
import { defineComponent, computed, reactive } from "vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import ValidationPasswordField from "@/components/atomics/ValidationPasswordField.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import XidButton from "@/components/atomics/XIDButton.vue";
import { RepositoryFactory, AuthRepository } from "@/lib/https";
import { storage, key } from "@/lib/storage";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import { useRouter } from "vue-router";
import { useForm } from "vee-validate";
const baseDomain: string = process.env.VUE_APP_API_URL as string;

export default defineComponent({
  name: "Login",
  components: {
    ValidationTextField,
    ValidationPasswordField,
    FlatButton,
    XidButton,
},

  props: {
    isContractLogin: { type: String, default: "false" },
    requireAccessCode: { type: String, default: "false" },
    afterLoginData: { type: String, default: "" },
    type: {type: String, default:"password"}
  },

  setup(props) {
    const router = useRouter();

    const { loginByEmail } =
      RepositoryFactory.getRepository<AuthRepository>(AuthRepository);
    const form = useForm({
      initialValues: {
        email: "",
        password: "",
      },
    });
    const { formHandle, formValues } = useFormHandler(form, loginByEmail);

    const submit = async function () {
      const response = await formHandle().catch((e) => {
        form.setErrors({
          email: e.data.message,
        });
      });
      if (response) {
        if (response.is2FARequired) {
          const localStorage = storage.getLocalStorage();
          localStorage.add("userType", response.type);
          if (response.totp)
            router.push({
              name: "TwoFactorRequire",
              params: {
                ...formValues,
                totpSecret: response.totp.secret,
                qrCode: `data:image/png;base64, ${response.totp.qrCode}`,
                ...props,
              },
            });
          else
            router.push({
              name: "TwoFactor",
              params: { ...formValues, ...props },
            });
        } else {
          const localStorage = storage.getLocalStorage();
          localStorage.add("userType", response.type);
          localStorage.add(key.sessionId, response.sessionId);

          const data = { ...response } as any;
          if (data.type === "onboarding") {
            if (data.onboardingStepRequired === "terms") {
              router.push({
                name: "Welcome",
                query: {
                  isCompanyCreating: data.isCompanyCreating,
                },
              });
            } else if (data.onboardingStepRequired === "company") {
              router.push({
                name: "Organization",
              });
            } else if (data.onboardingStepRequired === "user") {
              router.push({
                name: "Profile",
              });
            }
          } else if (props.isContractLogin == "true") {
            const localStorage = storage.getLocalStorage();
            const guestCode = localStorage.get("guestCode");
            const decode = Buffer.from(guestCode, "base64").toString();

            const details = JSON.parse(decode);
            localStorage.add("userType", response.type);

            if (props.requireAccessCode === "true")
              router.push({
                name: "Access Code",
              });
            else router.push(`/sign/details?contractId=${details.contractId}`);
          } else {
            const localStorage = storage.getLocalStorage();
            localStorage.add("userType", response.type);
            if (props.afterLoginData) {
              const afterLoginData = JSON.parse(props.afterLoginData);
              router.push({
                name: afterLoginData.name,
                query: afterLoginData.query,
                params: afterLoginData.params,
              });
            } else {
              router.push({ name: "Dashboard" });
            }
          }
        }
      }
    };

    const xidLogin = () => {
      const localStorage = storage.getLocalStorage();
      const guestCode = localStorage.get("guestCode");
      if(guestCode && props.isContractLogin == "true") {
        localStorage.add("requireAccessCode", props.requireAccessCode);
      }
      window.open(`${baseDomain}/auth/login-by-xid`,"_self");
    }

    return {
      submit,
      xidLogin,
    };
  },
});
